/* 
  LazyLoader 컴포넌트 CSS
  - Suspense 상태에서 로딩 이미지를 중앙에 정렬
  - 반응형에 따라 이미지 크기를 조정
*/

.lazy-loader {
    /* 컨테이너 중앙정렬 후 화면의 50%만큼의 높이를 사용 */
    display: flex;
    width:100%;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

/* 화면 너비 768px 미만:*/ 
.lazy-loader img {
    /* 기본 이미지 크기 설정 및 세로길이는 가로에 맞춰 자동 설정 */
    width: 150px;
    height: auto;
}

/* 반응형 스타일 */
/* 화면 너비 768px 이상:*/ 
@media screen and (min-width:768px) {

    .lazy-loader img {
        width: 200px;

    }
  

}

/* 화면 너비 1024px 이상:*/
@media screen and (min-width:1024px) {
    .lazy-loader img {
        width: 300px;
    }
}


