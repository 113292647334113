/* 공용 폰트 스타일 적용 */
@font-face{
  font-family: 'NanumMyeongjo';
  src: url('../fonts/NanumMyeongjo.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* global.css */
* {
  font-family: 'NanumMyeongjo', serif;
  }
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
  margin:0px;
}

