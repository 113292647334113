.dropdown--on {
  list-style: none;
  padding: 0;
}

.dropdown--off {
  display: none;
}


/* 
 - 드롭다운을 켤때
     dropdown-enter => dropdown-enter-active
 
 - 드롭다운을 끌때
     dropdown-exit => dropdown-exit-active
*/

.dropdown-enter {
  opacity: 0;                   /* 초기 상태: 투명하게 시작 */
  transform: translateY(-10px); /* 초기 상태: 살짝 위에 있도록 */
}

.dropdown-enter-active {
  opacity: 1;               /* 애니메이션 종료 상태: 불투명하게 */
  transform: translateY(0); /* 애니메이션 종료 상태: 원래 위치로 이동(-10px => 0px) */
  transition: opacity 300ms, transform 300ms; /* 300ms 동안 전환 효과 적용 */
}

.dropdown-exit {
  opacity: 1;               /* 나가기 시작할 때: 불투명한 상태 유지 */
  transform: translateY(0); /* 나가기 시작할 때: 원래 위치 유지 */
}

.dropdown-exit-active {
  opacity: 0;                   /* 애니메이션 종료 상태: 투명하게 */
  transform: translateY(-100px); /* 애니메이션 종료 상태: 살짝 위로 이동(0px => -10px) */
  transition: opacity 300ms, transform 300ms; /* 300ms 동안 전환 효과 적용 */
}
