/* 푸터 전체 컨테이너 스타일 */
.footer-container {
    display: flex;
    position: relative;
    width: 80%;
    background-color: #ffffff;
    height: auto;

    /* 부모 요소로부터 가운데 정렬 */
    left: 50%;
    padding-top: 1%;
    transform: translateX(-50%);
    justify-content: center;

}

/* 내부 컨테이너  */
.footer-inner-container {
    display: flex;
    justify-content: center;
    padding: 0 20px;
    width: 100%;
    
}

/* 콘텐츠 영역 */
.content {
    display: flex;
    flex-direction: column; /* 콘텐츠마다 세로 방향으로 정렬 */
    width: 100%;
    border-top: 1px solid #e2e5e9;
    height: auto;
    padding-top: 15px;
    padding-bottom: 50px;
}

.content span {
    font-size: 10px;
}

