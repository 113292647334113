/* 에러 페이지 컨테이너 */
/* 화면의 가운데로 배치 및 요소들을 세로로 나열 */
.error-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* 에러 페이지 텍스트 문자 */
.error-page__message {
    font-size: 30px;
}

/* 에러 페이지 link */
.error-page__link {
    display: inline-block;
    text-decoration: none;
    color: #333;
    font-size: 15px;
    margin-top: 20px;
    background-color: #dfdfdf; 
    border-radius: 8px;
    transition: all 0.3s ease; 
    padding: 10px 20px;

}

/* link 마우스 hover 상태 */
.error-page__link:hover {
    background-color: #a6a6a6; 
    color: #fff;
    transform: translateY(-3px); 
}



@media screen and (min-width: 480px) {
    .error-page__message {
        font-size: 40px;
    }

    .error-page__link {
        font-size: 20px;
        margin-top: 30px;
        padding: 15px 30px;
    }
}