.root-layout {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #cfcece;
}

.main-content {
  flex : 1;
  display: flex;
  position: relative;
  width: 80%;
  margin-top: 70px;
  margin-right: 0;
  background-color: #ffffff;
  /* 부모요소로부터 가운데정렬을 시키는방법
  left로 요소를 부모 요소의 왼쪽 경계로부터 50% 지점에 위치시킴
  현재 위치에서 자신의 너비 50%만큼 왼쪽으로 이동 */
  left: 50%;
  transform: translateX(-50%);

}


