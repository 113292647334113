
/* 네비게이션 자체 컨테이너  */
.navbar {
    margin-left: 4rem;
}

@media (max-width : 767px) {
    .navbar {
        display: none;
    }
}    


/* 메뉴 컨테이너 */
.navbar__menus {
    display :flex;
    align-items: center;
    justify-content: flex-start;
    list-style:none;
}

.navBar__menus--off {
    display : none;
}