/* header 컨테이너 */
.header {
    position: fixed;
    background:#333;
    z-index: 99;
    top:0;
    right:0;
    left:0;
    height: auto ;
}


/* header inner 컨테이너*/
.header__content{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding : 1rem 2rem;
    height: 70px;
    margin : 0 1rem;
}

/* header left 콘텐츠 영역 */
.header__left-group {
    display: flex;
    align-items: center;
}

/* header right 콘텐츠 영역 */
.header__right-group {
    display: none;
}

@media (max-width : 768px) {
    .header__right-group {
        display : flex;
    }
}