

.header__right-group__button {
    background-color: transparent;
    cursor: pointer;
    border:none;
}


.header__right-group__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
}