.dropdown--on {
    position: absolute;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 7rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    
}

.dropdown--off {
    display: none;
}


/* 최상위 드롭다운 클래스 dpeth = 1 */
.dropdown__primary {
    top : 135.5%;
    left: 0;
}

/* 하위 드롭다운 클래스 dpeth = 2 */
.dropdown__secondary {
    left : 100.5%;
    top : -7%;
}



