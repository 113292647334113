
/* 최상단 내비게이션 메뉴 컨테이너 */
.menu-item {
  position: relative;
}

/* 최상단 내비게이션 메뉴 */
.menu-item__top {
  color: white;
  background-color: transparent;
  margin-right: 4rem;
  margin-bottom: 0.25rem;
  font-size: 1.1rem;
}

/* 최상단 내비게이션 메뉴의 버튼 */
.menu-item__top-button {
  position: relative;
  cursor: pointer;
  border: none;
}

/* 최상단 내비게이션 메뉴의 링크 */
.menu-item__top-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
}

/* 최상단 메뉴 hover 시 크기 확대 */
.menu-item__top-button:hover,
.menu-item__top-link:hover {
  transform: scale(1.06);
}

/* 최상단 메뉴의 밑줄 애니메이션 */
.menu-item__top--line {
  content: "";
  position: absolute;
  width: 0;
  height: 2.7px;
  display: block;
  margin-top: 5px;
  left: 0;
  background: white;
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease;
}
.menu-item__top--line-on {
  width: 100%;
}

/* 하위 메뉴 (submenu) */
.menu-item__submenu {
  width: 100%;
  color: black;
  font-weight: bold;
  background-color: transparent;
  font-size: 16px;
  padding: 0.5rem 1rem;
}

/* 하위 메뉴의 버튼 */
.menu-item__submenu-button {
  cursor: pointer;
  border: none;
}

/* 하위 메뉴의 활성화된 버튼 */
.menu-item__submenu-button--active {
  background-color: #f2f2f2;
}

/* 하위 메뉴 버튼 및 링크 hover 시 배경색 변경 */
.menu-item__submenu-button:hover,
.menu-item__submenu-link:hover {
  background-color: #f2f2f2;
}

/* 하위 메뉴의 링크 */
.menu-item__submenu-link {
  display: block;
  text-decoration: none;
}

/* 드롭다운 화살표 (아래) */
.menu-item__arrow--down {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

/* 드롭다운 화살표 (오른쪽) */
.menu-item__arrow--right {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.32em solid transparent;
  border-bottom: 0.32em solid transparent;
  border-left: 0.42em solid;
}